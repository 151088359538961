import './styles/bootstrap.min.css';
import './styles/photoswipe.css';
import './styles/default-skin/default-skin.css';
import './styles/jquery-confirm.css';

import jQuery from 'jquery';

import Swiper, { Navigation, Pagination, Mousewheel} from 'swiper';

// swiper bundle styles
import 'swiper/css/bundle'

// swiper core styles
import 'swiper/css'

// modules styles
import 'swiper/css/navigation'
import 'jquery-confirm';
import './styles/hamburgers.min.css';

// Photoswiper
import * as PhotoSwipe from 'photoswipe';
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

var config = {
    baseUrl: "https://lebo.pl",
    imgUrl: "images/product_photos",
    lang: "de"
}

// Swiper Configuration
 Swiper.use([Navigation, Pagination]);

 import './styles/style.css';

var mySwiper = new Swiper('.swiper', {
    spaceBetween: 0,
    modules: [Navigation, Pagination, Mousewheel],
    speed: 800,
    direction: 'vertical',
    mousewheel: {
        sensitivity: 100,
        releaseOnEdges: true,
    },
    effect: 'slide',
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    on: {
        slideNextTransitionStart: function() {
            if (mySwiper.activeIndex == 1) {
                jQuery('.hm0-sky').css('zIndex', 9);
            }
            if (mySwiper.activeIndex == 1) {
                //jQuery('.title-decoration').addClass('animated')
            }
        },
        slideChangeTransitionStart: function() {

            if (mySwiper.activeIndex > mySwiper.previousIndex) {
                var direction = "forward";
            } else {
                var direction = "backward";
            }

            if (mySwiper.activeIndex == 0) {
                jQuery('.hm0-sky').css('zIndex', 9);
            } else if (mySwiper.activeIndex == 1) {

            } else if (mySwiper.activeIndex == 2) {

                jQuery('.page-content').css('background-color', '#575761')
            } else if (mySwiper.activeIndex == 3) {
                if (direction == "forward") {
                    jQuery('.page-content').css('background-color', '#333A46')
                } else {
                    jQuery('.page-content').css('background-color', '#575761')
                }
                
            } else {
                //jQuery('body').css('background-color', '#F8F9FB')
            }
        },
        slideChangeTransitionEnd: function() {

            if (mySwiper.activeIndex > mySwiper.previousIndex) {
                var direction = "forward";
            } else {
                var direction = "backward";
            }
            if (mySwiper.activeIndex == 0) {
                jQuery('.hm0-sky').css('zIndex', 9);
            } else if (mySwiper.activeIndex == 1) {

                //jQuery('.page-content').css('background-color', '#575761');
            } else if (mySwiper.activeIndex == 2) {
                //jQuery('.page-content').css('background-color', '#F8F9FB')
            } else if (mySwiper.activeIndex == 3) {
                //jQuery('.page-content').css('background-color', '#F8F9FB')
            } else if (mySwiper.activeIndex == 4) {

            }
        },
        slideChange: function() {
            // Pagination
            jQuery('.swiper-pagination-bullet-active').css('background', 'red');
            jQuery('.swiper-pagination-bullet').each(function() {
                if (jQuery(this).hasClass('swiper-pagination-bullet-active')) {
                    if (mySwiper.activeIndex == 1) {
                        jQuery(this).css('background', '#CCCCCC');
                        jQuery(this).css('border', '1px solid rgb(134, 134, 134)');
                    } else {
                        jQuery(this).css('background', '#dddddd');
                        jQuery(this).css('border', '1px solid grey');
                    }
                } else {
                    if (mySwiper.activeIndex == 1) {
                        jQuery(this).css('background', 'transparent');
                        jQuery(this).css('border', '1px solid rgb(134, 134, 134)');
                        //jQuery('.page-content').css('background-color', '#F8F9FB')
                    } else {
                        jQuery(this).css('background', 'transparent');
                        jQuery(this).css('borderr', '1px solid #ddd');
                    }
                    if (mySwiper.activeIndex === 4) {
                        setTimeout(function() {
                            hideGmapsReviews();
                        },5000);
                        
                    }
                }
            });
            // Hamburger
            /*
            if(mySwiper.activeIndex == 1 || mySwiper.activeIndex == 4) { 
                setTimeout(function(){
                    jQuery(".hamburger-inner").addClass("dark");
                },650);
            } else {
                setTimeout(function(){
                    jQuery(".hamburger-inner").removeClass("dark");
                },650);
            }
            */
        }
    }
});


setTimeout(function() {
    jQuery('.welcome-text').show();
}, 1);


setTimeout(function() {
    loadProducts(1);
    cookieCheck();
    bindEvents();
    setTimeout(function() {
        loadProduct(6);
    }, 500);
    
}, 100);



setTimeout(function() {

    jQuery('.hm0-sky').addClass('animated');
    jQuery('.nav-item-lebo').addClass('animated');
    jQuery('.logo-decoration').addClass('animated');
    setTimeout(function() {
        jQuery('.slogan-discover').addClass('animated');
        jQuery('.logo-text').addClass('animated');
    }, 100);


    /*setTimeout(function(){
        jQuery('.slogan-why').addClass('animated');
    },1500);*/

    setTimeout(function() {
        jQuery('.slogan-windows').addClass('animated');

    }, 400);

    setTimeout(function() {
        jQuery('.slogan-love-text').addClass('animated');
    }, 700);


    setTimeout(function() {
        jQuery('.logo-container').addClass('animated')
    }, 100);

    updateCopyright();
    fillOpeningHours();

},20);

function preloadImage(url) {
    var img = new Image();
    img.src = url;
}


function bindEvents() {
    jQuery("#toggleCookineInfoCheckButton").click(() => {
        toggleCookierInfo();
    });
    jQuery("#toggle-menu-button").click(() => {
        toggleMenu();
    });
    jQuery(".menu-button").click(function() {
        const index = jQuery(this).parent().index();
        slideTo(index);
    });
    jQuery(".products-single-item, .products-menu-item").click(function() {
        const dataTabId = parseInt(jQuery(this).attr("data-tab-id"));
        const dataTabName = jQuery(this).attr("data-tab-name");
        loadProducts(dataTabId, dataTabName);
    });
    jQuery("#contact-form-button").click(() => {
        askAboutFiles();
        //submitContactForm();
    });   
    jQuery(".realisation-box").click(function() {
        const category = jQuery(this).attr("data-category");
        browserOurWork(category);
    });
    bindFileUploaderEvents();
}

function bindFileUploaderEvents() {
    jQuery("#contactFormUploader").change(function() {
        const form = jQuery('#contactFileForm')[0];
        const fd = new FormData();
        const files = form[0].files;
        if(files.length > 0) {
            const file = files[0];
            fd.append("file", file);
            jQuery.ajax({
                url:"https://lebo.pl/kalkulator/api/website-contact-photo",
                type: 'post',
                data: fd,
                contentType: false,
                processData: false,
                success: function(response){
                    let filename = "";
                    if(response.filename) {
                        filename = response.filename;
                    }
                    console.log(filename);
                    submitContactForm(filename);
                 },
           });
        }
    });
}

function bindProductsSumenutEvents() {
    jQuery(".products-submenu-item").click(function() {
        const dataTabId = parseInt(jQuery(this).attr("data-tab-id"));
        loadProduct(dataTabId);
    });
}

function toggleMenu() {
    var isVisible = jQuery('#menu').hasClass('visible');
    if (isVisible) {
        jQuery('#menu').removeClass('visible');
        jQuery('.hamburger--collapse').removeClass('is-active');
        jQuery('.menu-right-element').removeClass('active');
    } else {
        jQuery('#menu').addClass('visible');
        jQuery('.hamburger--collapse').addClass('is-active');
        jQuery('.menu-right-element').addClass('active');
    }
}


function cookieCheck() {
    var cookieAlreadyAccepted = window.localStorage.getItem("lebofenster_cookie_accepted");
    var cookieBanner = document.getElementsByClassName("nk-cookie-banner")[0];
    //if(false) {
    if (cookieAlreadyAccepted === "yes") {
        cookieBanner.style.display = "none";
    } else {
        cookieBanner.style.display = "block";
    }
}


function toggleCookierInfo() {
    window.localStorage.setItem("lebofenster_cookie_accepted", "yes");
    cookieCheck();
}

function i18nHelper() {
    return "Send"
}


function slideTo(index) {
    if (mySwiper) {
        mySwiper.slideTo(index)
        setTimeout(function() {
            toggleMenu();
        }, 300);
    }
}

function markTabAsActive(newTabName) {
    jQuery(".products-single-item").removeClass("active");
    jQuery(".products-single-item").each(function() {
        var tabName = jQuery(this).data("tab-name");
        if (tabName === newTabName) {
            jQuery(this).addClass("active");
        }
    });
    // mobile
    jQuery(".products-menu-item").removeClass("active");
    jQuery(".products-menu-item").each(function() {
        var tabName = jQuery(this).data("tab-name");
        if (tabName === newTabName) {
            jQuery(this).addClass("active");
        }
    });
}

function markSubmenuTabAsActive(newTabId) {
    jQuery(".products-submenu-item").removeClass("active");
    jQuery(".products-submenu-item").each(function() {
        var tabId = jQuery(this).data("tab-id");
        if (parseInt(tabId) === parseInt(newTabId)) {
            jQuery(this).addClass("active");
        }
    });
}

function loadProduct(productId) {
    jQuery.ajax({
        cache: false,
        type: "GET",
        url: "https://lebo.pl/php/product_details.php?id=" + productId + "&lang=" + config.lang,
        dataType: 'json',
        jsonp: 'onJSONPLoad',
        crossDomain: true,
        success: function(result) {
            setProductData(productId, result.name, result.content);
            markSubmenuTabAsActive(productId);
        }
    });
}

function loadProducts(categoryId, tabName) {
    jQuery.ajax({
        cache: false,
        type: "GET",
        url: "https://lebo.pl/php/products.php?id_category=" + categoryId + "&lang=" + config.lang,
        dataType: 'json',
        jsonp: 'onJSONPLoad',
        crossDomain: true,
        success: function(results) {
            setProductsMenu(results);
            if (tabName) {
                markTabAsActive(tabName);
            }
            bindProductsSumenutEvents();
            // Get first possible item
            if(results[0]) {
                const firstFoundItem = results[0];
                loadProduct(firstFoundItem.id);
            }
        }
    });
}

function setProductsMenu(products) {
    jQuery("#jq-products-submenu-items").html("");
    jQuery.each(products, function(i, resource) {
        var activeCssClass = "active";
        if (i > 0) {
            activeCssClass = "";
        }
        var content = '<li class="products-submenu-item ' + activeCssClass + '" data-tab-id="' + (resource.id) + '"><a href="javascript:void(0)">' + resource.name + '</a></li>';
        jQuery("#jq-products-submenu-items").append(content);
    });
}

function setProductData(productId, productName, productDesc) {
    setProductName(productName);
    setProductDesc(productDesc);
    const url = require("./images/product_photos/" + productId + ".jpg");
    setProductImage(url);
}

function setProductName(productName) {
    jQuery("#jq-product-name").html("");
    jQuery("#jq-product-name").html(productName);
}

function setProductDesc(productDesc) {
    if (window.screen.width < 800) {
        productDesc = shorten(productDesc, 480, ". ");
    }
    jQuery("#jq-product-desc").html("");
    jQuery("#jq-product-desc").html(productDesc);
}

function shorten(str, maxLen, separator = ' ') {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen));
}

function setProductImage(imageUrl) {
    jQuery("#jq-product-image-mobile").attr("src", imageUrl);
    jQuery("#jq-product-image").attr("src", imageUrl);
}

function submitContactForm(filename = "") {
    jQuery("#contact-container").hide();
    jQuery("#contact-loader").show();
    var username = jQuery('#username-form').val();
    var email = jQuery('#email-form').val();
    var message = jQuery('#message-form').val();
    jQuery.ajax({
        cache: false,
        type: "POST",
        url: "https://lebo.pl/kalkulator/api/lebo-website-mail",
        data: {
            username: username,
            email: email,
            message: message,
            filename:filename
        },
        success: function(res) {
            jQuery('#username-form').val("");
            jQuery('#email-form').val("");
            jQuery('#message-form').val("");
            jQuery("#contact-loader").hide();
            jQuery("#contact-loader-ty").show();
            setTimeout(function() {
                jQuery("#contact-loader-ty").hide();
                jQuery("#contact-container").show();
            }, 10000);
        }

    });
}

function askAboutFiles() {
    jQuery.confirm({
        theme: 'supervan',
        animation: 'zoom',
        columnClass: 'col-lg-8',
        closeIcon:true,
        closeAnimation: 'scale',
        escapeKey: true,
        title: 'Dateien hochladen?',
        content: 'Möchten Sie einige Dateien hochladen? <br/>Z.B. Sie können Dateien mit den Größen des Fensters einschließen.',
        buttons: {   
            no: {
                text:"Nein, Kontaktformular ohne Dateien senden",
                action:function() {
                    submitContactForm();
                }
            },
            yes: {
                text: "Ja, ich möchte auch eine Datei senden",
                keys: ['enter'],
                action: function(){
                    jQuery("#contactFormUploader").click();
                }
            },
            cancel: {
                text:"Abbrechen",
                keys:["esc"]
            }
        }
    });
}

function fillOpeningHours() {
    const hoursArr = [];
    hoursArr.push(getDayOpenHoursTemp("Montag", "8:00 - 16:00"));
    hoursArr.push(getDayOpenHoursTemp("Dienstag", "8:00 - 16:00"));
    hoursArr.push(getDayOpenHoursTemp("Mittwoch", "8:00 - 16:00"));
    hoursArr.push(getDayOpenHoursTemp("Donnerstag", "8:00 - 16:00"));
    hoursArr.push(getDayOpenHoursTemp("Freitag", "8:00 - 16:00"));
    hoursArr.push(getDayOpenHoursTemp("Samstag", "Termine nach Anfrage"));
    const hoursHtml = hoursArr.join("<br/>");
    jQuery(".opening-hours").html(hoursHtml);
}

function getDayOpenHoursTemp(day, hour) {
    return "<div class='opening-hours-line'><span class='opening-hours-day'>"+day+"</span><span class='opening-hours-hour'>"+hour+"</span></div>"
}

function browserOurWork(category = "all") {
    var url = "https://lebo.pl/admin/api/get_projects.php";
    jQuery.ajax({
            url: url,
        })
        .then(res => {
            var items = [];
            var resAsJson = JSON.parse(res);
            for (var i = 0; i < resAsJson.length; i++) {
                var filename = resAsJson[i].filename;
                var src = "https://lebo.pl/img/gallery/" + filename;
                if(category !== "all" && (category.toLowerCase() === resAsJson[i].category.toLowerCase())) {
                    items.push({
                        src: src,
                        w: 1200,
                        h: 800
                    });
                }
            }

            var pswpElement = document.querySelectorAll('.pswp')[0];
            // build items array
            // define options (if needed)
            var options = {
                // optionName: 'option value'
                // for example:
                index: 0 // start at first slide
            };
            // Initializes and opens PhotoSwipe
            var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();
        });
}

function updateCopyright() {
    const currentYear = new Date().getFullYear();
    jQuery(".footer-copyright").html(`Lebofenster © ${currentYear}. All Rights Reserved`);
}

function hideGmapsReviews() {
    jQuery(".place-card-large").hide();
}

var aboutUsContent = jQuery("#about-us-content-mobile").html();

var mditContentSwitcher = function(text) {
    var currentPage = 1;
    var contentArr = [];
    var config = {
        maxTextLength: 480,
        rightArrowIcon: "→",
        leftArrowIcon: "←"
    };
    var init = function(text) {
        createPagesContent(text);
        switchToContent(1);
    };
    var injectHtml = function(divId, text) {
        clearDivHtml(divId);
        jQuery("#" + divId).html(text);
    };
    var clearDivHtml = function(divId) {
        jQuery("#" + divId).html("");
    };
    var shortenText = function(text, maxLen, startingPoint = 0, separator = ' ') {
        if (text.length <= maxLen) {
            return text;
        } else {
            return text.substr(startingPoint, text.lastIndexOf(separator, maxLen));
        }
    };
    var createPagesContent = function(text) {
        var numberOfPages = getNumberOfPages(text, config.maxTextLength);
        contentArr = generateContentArr(text, numberOfPages);
        var pagesHtml = generatePagesHtml(numberOfPages);
        injectHtml("mobile-pagination", pagesHtml);
        addPagesButtonsEvents(numberOfPages);
    }
    var getNumberOfPages = function(text, maxCharacters) {
        var textLength = text.length;
        //var numberOfPages = Math.round((textLength / maxCharacters) + 1);
        var numberOfPages = generateContentArr(text).length;
        return numberOfPages;
    }
    var generatePagesHtml = function(numberOfPages) {
        var html = "";
        html = html + "<span data-content-arrow='left' class='arrow not-active'>" + config.leftArrowIcon + "</span>";
        html = html + "<span data-content-arrow='right' class='arrow active'>" + config.rightArrowIcon + "</span>";
        return html;
    }
    var generateContentArr = function(text, numberOfPages) {
        var contentArr = [];
        var temp = text.split("<mob>");
        for (var i = 0; i < temp.length; i++) {
            var startingPoint = (i * config.maxTextLength);
            var content = temp[i];
            contentArr.push({
                page: (i + 1),
                content: content
            });
        }
        return contentArr;
    }
    var addPagesButtonsEvents = function(numberOfPages) {
        var numberOfPages = getNumberOfPages(text, config.maxTextLength);
        jQuery(".arrow").each(function(index) {
            var leftOrRight = jQuery(this).data("content-arrow");
            jQuery(this).click(function() {
                if (jQuery(this).hasClass("active")) {
                    if (currentPage > 0 && currentPage < numberOfPages) {
                        if (leftOrRight === "left") {
                            currentPage = currentPage - 1;
                        }
                        if (leftOrRight === "right") {
                            currentPage = currentPage + 1;
                        }
                        switchToContent(currentPage);
                    }
                }
            });
        });
    }
    var updateArrowsClasses = function(currentPage) {
        var numberOfPages = getNumberOfPages(text, config.maxTextLength);
        jQuery(".arrow").removeClass("not-active");
        jQuery(".arrow").addClass("active");
        jQuery(".arrow").each(function(index) {
            var whichDirection = jQuery(this).data("content-arrow");
            if (whichDirection === "left" && currentPage <= 1) {
                jQuery(this).removeClass("active");
                jQuery(this).addClass("not-active");
            }
            if (whichDirection === "right" && currentPage >= (numberOfPages - 1)) {
                jQuery(this).removeClass("active");
                jQuery(this).addClass("not-active");
            }
        });
    }
    var switchToContent = function(pageIndex) {
        var pageContent = contentArr.find(page => page.page === pageIndex).content;
        injectHtml("about-us-content-mobile", pageContent);
        updateArrowsClasses(pageIndex);
    }
    //var hideAllOtherContentPages = function() {
    //  jQuery(".pages-content").hide();
    //}

    init(text);
}(aboutUsContent);